import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { AntDesignOutlined } from "@ant-design/icons";
import { logout } from "../../../reduxApp/authSlice";
import { RxDashboard } from "react-icons/rx";
import { PiUsersFill } from "react-icons/pi";
import { MdReportProblem } from "react-icons/md";
import { BiNotification } from "react-icons/bi";
import { RiCoinsFill } from "react-icons/ri";
import styled from "styled-components";
import LogoutModal from "../../modals/LogoutModal";
import { logo } from "../../../utils/image";

const navbarPath = [
  {
    path: "/layout/dashboard",
    icon: <RxDashboard size={20} />,
    name: "Dashboard",
  },
  {
    path: "/layout/users",
    icon: <PiUsersFill size={20} />,
    name: "Users",
  },
  {
    path: "/layout/reported",
    icon: <MdReportProblem size={20} />,
    name: "Reported",
  },
  {
    path: "/layout/subscription",
    icon: <BiNotification size={20} />,
    name: "Subscription",
  },
  // {
  //   path: "/layout/coins-managment",
  //   icon: <RiCoinsFill size={20} />,
  //   name: "Coins Managment",
  // },
];

function SideNavbar({
  navopenClose,
  setNavopenClose,
  firstime,
  navopenClose2,
  setNavopenClose2,
}) {
  const dispatch = useDispatch();

  const [openModal, setOpenModal] = useState(false);
  const handleLogout = () => {
    dispatch(logout(null));
  };
  const handleClose = () => setOpenModal(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setNavopenClose2(true);
    });
    return () => clearTimeout(timer);
  }, [navopenClose]);

  return (
    <>
      <LogoutModal
        handleOk={handleLogout}
        handleCancel={handleClose}
        openModal={openModal}
      />

      {navopenClose2 && (
        <>
          <SidebarStyle navopenClose={navopenClose} firstime={firstime}>
            <div className="sidebar-logo">
              <img className="image-app-logo" src={logo} alt="Logo" />
            </div>
            {navbarPath?.map((el) => (
              <NavLink
                onClick={() =>
                  window.innerWidth <= 1080 && setNavopenClose(!navopenClose)
                }
                className="sideNavabtn"
                to={el.path}
                key={el.name}
              >
                {el.icon}
                {el.name}
              </NavLink>
            ))}
            <div className="logout-btn-container">
              <button onClick={() => setOpenModal(true)} className="logout-btn">
                <AntDesignOutlined />
                Logout
              </button>
            </div>
          </SidebarStyle>
        </>
      )}
    </>
  );
}

export default SideNavbar;

const SidebarStyle = styled.div`
  background-image: linear-gradient(#0eaace, #a4d8f0);
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 996;
  transition: all 0.3s;
  box-shadow: 0px 0px 20px rgba(1, 41, 112, 0.1);
  scrollbar-color: #aab7cf transparent;
  color: black;
  height: ${({ navopenClose }) => (navopenClose ? "100vh" : "0")};
  /* width: ${({ navopenClose }) => (navopenClose ? "250px" : "100%")}; */
  width: ${({ navopenClose }) => (navopenClose ? "250px" : "250px")};
  padding: ${({ navopenClose }) => (navopenClose ? "10px" : "0")};
  position: fixed;
  z-index: 995;
  overflow: hidden;
  animation-name: ${({ navopenClose, firstime }) =>
    navopenClose ? (firstime === 1 ? "fistanim" : "anim") : "anim2"};
  animation-duration: 0.5s;
  /* border-radius: ${({ navopenClose }) =>
    navopenClose ? "0" : "0  0 0 100%"}; */

  @keyframes anim {
    from {
      height: 0px;
    }
    to {
      height: 100vh;
    }
  }
  @keyframes fistanim {
    from {
      height: 100vh;
    }
    to {
      height: 100vh;
    }
  }
  @keyframes anim2 {
    from {
      height: 100vh;
    }
    to {
      height: 0px;
    }
  }

  .sideNavabtn {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 5px;
    color: #000000;
    text-decoration: none;
    font-size: 16px;
    padding: 10px 15px;
    margin-bottom: 10px;
    border-radius: 8px;
    transition: background-color 0.3s ease, color 0.3s ease;
    font-weight: 600;
    &:hover {
      color: #4154f1;
      background: #f6f9ff;
    }

    &.active {
      background-color: #08213f;
      color: #fff;
    }
  }

  .logout-btn-container {
    margin-top: auto;
    position: relative;
    bottom: 10px;
  }

  .sidebar-logo {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }

  .image-app-logo {
    height: ${({ navopenClose }) => (navopenClose ? "12vh" : "12vh")};
  }

  .logout-btn {
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 16px;
    padding: 5px 12px;
    gap: 5px;
    border-radius: 20px;
    border: none;
    font-weight: 500;
    color: #ffffff;
    background: #f5005a;
    cursor: pointer;
  }

  @media (max-width: 1080px) {
    width: 200px;
    height: ${({ navopenClose }) =>
      navopenClose ? "calc(100vh - 44px)" : "0"};
    padding: ${({ navopenClose }) => (navopenClose ? "10px" : "0")};
    position: fixed;
    margin-top: 46px;
    z-index: 995;
    overflow: hidden;
    animation-name: ${({ navopenClose }) => (navopenClose ? "anim" : "anim2")};
    animation-duration: 0.6s;
    border-radius: 0;

    @keyframes anim {
      from {
        height: 0;
      }
      to {
        height: calc(100vh - 44px);
      }
    }
    @keyframes anim2 {
      from {
        height: calc(100vh - 44px);
      }
      to {
        height: 0;
      }
    }

    .sidebar-logo {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 30px;
    }
    .sidebar-logo img {
      height: 7vh;
    }

    .logout-btn-container {
      bottom: 0;
      margin-top: 20px;
    }

    .sideNavabtn {
      font-size: 14px;
      padding: 8px 10px;
    }
  }
`;
